import React, {FunctionComponent, SVGProps} from 'react'
import {Container} from "./styled";

interface ITextLink extends React.HTMLAttributes<HTMLDivElement> {
    text: string;
    StartIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
    EndIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
    color: string;
}

const TextLink = ({text, color, EndIcon, StartIcon, ...other}: ITextLink) => {
    return (<Container color={color} {...other}>
        {StartIcon && <StartIcon/>}
        <span style={{marginRight: EndIcon && '8px', marginLeft: StartIcon && '8px'}}>{text}</span>
        {EndIcon && <EndIcon/>}
    </Container>)
};

export default TextLink