import styled from 'styled-components';

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px 70px 70px 70px;
    button{
      display: flex;
      margin-left: auto;
    }
`;

export const TimeContainer = styled.div`
  text-align: center;
`;