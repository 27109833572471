import styled from 'styled-components';
import {TableHead} from "@material-ui/core";

export const TableHeadStyled = styled(TableHead)`
    .MuiTableCell-head {
        border-bottom: none !important;
    }
`;

export const CellContent = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    min-height: 37px;
    max-height: 37px;
    
    .MuiTableSortLabel-root{
        position: absolute;
        top: 11px;
        right: -8px;
    }
    .MuiTextField-root{
      width: 100%;
    }
    &:hover{
        .MuiTableSortLabel-root[aria-disabled="false"]{
            & > * {
                opacity: 0.4;
            }
        }
    }
    .MuiTextField-root{
        label{
            font-size: 0.875rem;
        }
        .MuiInputLabel-formControl{
            transform: translate(0, 12px) scale(1);
        }

        .MuiInputLabel-shrink{
            transform: translate(0, 1.5px) scale(0.75);
        }
        & > *.MuiInput-formControl{
            margin-top: 5px;
        }
    }
`;