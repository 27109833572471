import React, {useState} from 'react';
import {useHistory} from 'react-router-dom'
import {ErrorContainer, LoginContainer, ImageStyled, PageContainer} from './styled';
import backgroundImage from '../../Images/image_background.png'
import {TextField, FormControlLabel, Checkbox} from "@material-ui/core";
import ButtonStyled from "../../Components/Button";
import {PagesEnum} from "../../Enums/pages";
import {apiAuthorize} from "../../Api/Login";

export enum UserRole {
    MainAdmin = "mainAdmin",
    ParkingOwner = "parkingOwner",
}

interface IFields {
    login: string;
    password: string;
}

const LoginPage = () => {
    const [fields, setFields] = useState<IFields>({login: '', password: ''});
    const [error, setError] = useState('');
    const [isRememberMe, setIsRememberMe] = useState<boolean>(true);
    const history = useHistory();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {id, value} = e.target;
        setFields(prev => ({...prev, [id]: value}));
        setError('')
    };

    const handleLogin = async () => {
        if (!fields.login || !fields.password) {
            setError("Заполните все поля!");
            return;
        }
        try {
            let response = await apiAuthorize(fields.login, fields.password);
            if (isRememberMe) {
                localStorage.setItem('currentUser', response.token);
                localStorage.setItem('role', response.role);
            } else {
                sessionStorage.setItem('currentUser', response.token);
                sessionStorage.setItem('role', response.role);
            }
            if (response.role == UserRole.MainAdmin)
                history.push(`/${PagesEnum.adminPage}`);
            else if (response.role == UserRole.ParkingOwner) {
                history.push(`/${PagesEnum.parkingCard}`);
            }
        } catch (ex) {
            console.log(ex);
            setError("Не правильное сочетание логина/пароля")
        }
    };

    return (
        <PageContainer>
            <LoginContainer>
                <h2>Добро пожаловать!</h2>
                <div>Вход</div>
                <TextField
                    id="login"
                    value={fields.login}
                    onChange={handleChange}
                    placeholder="Логин"
                    variant="outlined"
                    size="small"
                />
                <TextField
                    id="password"
                    type="password"
                    value={fields.password}
                    onChange={handleChange}
                    placeholder="Пароль"
                    variant="outlined"
                    size="small"
                />
                <FormControlLabel
                    control={<Checkbox checked={isRememberMe}
                                       onChange={() => setIsRememberMe(!isRememberMe)}
                                       size="small"
                                       name="checked"
                    />}
                    label="Запомнить меня"
                />
                {error && <ErrorContainer>{error}</ErrorContainer>}
                <ButtonStyled onClick={handleLogin} size="small">
                    Вход
                </ButtonStyled>
            </LoginContainer>
            <ImageStyled src={backgroundImage}/>
        </PageContainer>
    );
}

export default LoginPage;