import styled from 'styled-components';

export const PageContainer = styled.div`
  .MuiAppBar-colorPrimary {
    display: flex;
    flex-direction: row;
    background-color: white;
    box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.25);

    & > p {
      margin-left: 40px;
      color: #4FB08D;
    }
  }
`;

export const SectionContainer = styled.div`
  justify-content: center;
  border-radius: 5px;
  flex-direction: column;
  display: flex;
  border: 2px solid #4FB08D;
  padding: 30px 50px 30px 50px;
  box-shadow: 5px 6px 8px rgba(0, 0, 0, 0.25);
  margin-left: 20px;
  margin-bottom: 30px;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 100px;
  max-width: 1150px;

  button {
    display: flex;
    margin-left: auto;
  }
`;