import styled, {createGlobalStyle} from 'styled-components'

export const PageContainer = styled.div`
  .MuiAppBar-colorPrimary {
    display: flex;
    flex-direction: row;
    background-color: white;
    box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.25);

    & > p {
      margin-left: 40px;
      color: #4FB08D;
    }
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  p {
    margin-right: 20px;
  }

  color: black;
  margin-left: auto;
`;

export const Container = styled.div`
  .MuiList-root {
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    margin-top: 80px;
  }

  .MuiListItem-button {
    width: fit-content;
    .MuiTypography-displayBlock {
      font-size: 26px;
    }
  }

  .MuiListItem-root.Mui-selected {
    color: #4FB08D;
    background-color: white;

    .MuiTypography-displayBlock {
      border-bottom: 3px solid #4FB08D;
      font-size: 26px;
    }

    &:hover {
      background-color: white;
    }
  }
`;


export const ContentContainer = styled.div`
  margin-bottom: 20px;
`;

export const GlobalSnackbarStyled = createGlobalStyle`
  .SnackbarItem-contentRoot-24 {
    position: relative;

    .SnackbarItem-message-25 {
      margin-right: 20px;
    }

    .SnackbarItem-action-26 {
      position: absolute;
      right: 7px;
    }
  }
`;