import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  color: ${(props: any) => props.color};
  user-select: none;

    &:hover{
      opacity: 0.7;
    }
    & > span{
      line-height: 16px;
      font-size: 18px;
    };
    
    & > svg{
      margin-top: 1px;
    }
`;