import React from 'react'
import {useField} from 'formik';
import {StandardTextFieldProps} from "@material-ui/core/TextField/TextField";
import TextFieldStyled from "../../../Components/TextFieldStyled";
import {FieldContainer, TitleContainer} from "./styled";

interface CustomTextFieldProps extends StandardTextFieldProps {
    isEditing?: boolean;
}

const CustomTextField = (props: CustomTextFieldProps) => {
    const [field] = useField(props.id ?? '');
    return (<FieldContainer>
        <TitleContainer className={"title"}>{props.title}</TitleContainer>
        {!props.isEditing ?
            <div>{field.value}</div> :
            <TextFieldStyled {...props} />
        }
    </FieldContainer>)
};

export default CustomTextField;