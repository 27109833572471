import React from 'react';
import {Placemark} from "react-yandex-maps";
import {IMarkInfo} from "../index";
import {green} from "@material-ui/core/colors";
import {apiChooseParking} from "../../../Api/Map";

interface IMarksGenerator {
    marks: IMarkInfo[];
    onMarkClick: (mark: IMarkInfo) => void;
}

const MarksGenerator = ({marks, onMarkClick}: IMarksGenerator) => {
    const getMarkColor = (count: number) => {
        if (count === 0)
            return '#8E8E93';
    };

    return (<>
        {marks.map((mark, i) =>
            <Placemark
                key={i}
                defaultGeometry={[mark.latitude, mark.longitude]}
                properties={{
                    iconContent: '<strong>' + mark.parkingSpaceCount + '</strong>',
                }}
                options={{
                    iconColor: getMarkColor(mark.parkingSpaceCount),
                }}
                onClick={onMarkClick(mark)}
            />)}
    </>);
};

export default MarksGenerator
