import React from 'react'
import {useField} from 'formik';
import {StandardTextFieldProps} from "@material-ui/core/TextField/TextField";
import TextFieldStyled from "../../../Components/TextFieldStyled";
import {FieldContainer, TitleContainer} from "./styled";
import {FormControl, MenuItem, Select} from "@material-ui/core";
import {ParkingSpacesType, russianNameFromParkingSpacesType} from "../../../Enums/parkingSpacesType";

interface VariableTextFieldProps extends StandardTextFieldProps {
    isEditing?: boolean;
    parkingSpacesType: ParkingSpacesType;
    onChangeType: (e: React.ChangeEvent<{name?: string | undefined; value: unknown}>) => void;
}

const VariableTextField = (props: VariableTextFieldProps) => {
    const [field] = useField(props.id ?? '');
    if (props.isEditing)
        return (<FieldContainer>
            <FormControl variant="outlined" size="small">
                <Select
                    value={props.parkingSpacesType}
                    defaultValue={ParkingSpacesType.numbers}
                    onChange={props.onChangeType}
                >
                    <MenuItem value={ParkingSpacesType.numbers}>{russianNameFromParkingSpacesType[ParkingSpacesType.numbers as unknown as number]}</MenuItem>
                    <MenuItem value={ParkingSpacesType.count}>{russianNameFromParkingSpacesType[ParkingSpacesType.count as unknown as number]}</MenuItem>
                </Select>
            </FormControl>
            <TextFieldStyled {...field} {...props} />
        </FieldContainer>)
    else
        return (<FieldContainer>
            <TitleContainer
                className={"title"}>{russianNameFromParkingSpacesType[props.parkingSpacesType as unknown as number]}</TitleContainer>
            <div className="field">{field.value}</div>
        </FieldContainer>)
};

export default VariableTextField;