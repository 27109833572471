import React, {useEffect, useState} from 'react';
import {Button, DialogActions, DialogContent, DialogContentText,} from "@material-ui/core";
import ButtonStyled from "../../Components/Button";
import {
    CardContainer,
    EditButtonContainer,
    PageContainer,
    SectionContainer,
    SubHeaderContainer,
    ToggleContainer,
} from "./styled";
import {Form} from '@unform/web';
import {Field, Formik} from "formik";
import {mixed, number, object, string} from 'yup';
import FocusError from "../../Helpers/FocusError";
import CustomTextField from "./CustomTextField";
import TextLink from "../../Components/TextLink";
import {
    apiGetParkingInfo,
    apiParkingSpacesValidateCount,
    apiParkingSpacesValidateNumbers,
    apiUpdateParking
} from "../../Api/Parking";
import ContentLoader from "../../Components/ContentLoader";
import Dialog from '@material-ui/core/Dialog';
import {IParkingInfo, parkingInfoInitial} from "../../Models/parkingInfo";
import VariableTextField from "./VariableTextField";
import {ParkingSpacesType} from "../../Enums/parkingSpacesType";
import {ParkingStatus} from "../../Enums/parkingStatus";


const ParkingCard = () => {
    const requiredMessage = "Обязательное поле";
    const [isEditingMode, setIsEditingMode] = useState(false);
    const [parkingInfo, setParkingInfo] = useState<IParkingInfo>(parkingInfoInitial);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState("");
    const handleClose = () => {
        setModalIsOpen(false);
    };
    useEffect(() => {
        (async () => {
            setParkingInfo((await apiGetParkingInfo())[0]);
            setIsLoading(false);
        })()
    }, []);

    const ParkingInfoSchema = object().shape({
        parkingStatus: mixed<ParkingStatus>(),
        description: string()
            .required(requiredMessage),
        title: string()
            .required(requiredMessage),
        parkingRules: string()
            .required(requiredMessage),
        workSchedule: string()
            .required(requiredMessage),
        contacts: string()
            .required(requiredMessage),
        location: string()
            .required(requiredMessage),
        geoPosition: string()
            .required(requiredMessage)
            .matches(/(^[-+]?(?:[1-8]?\d(?:\.\d+)?|90(?:\.0+)?)),\s*([-+]?(?:180(?:\.0+)?|(?:(?:1[0-7]\d)|(?:[1-9]?\d))(?:\.\d+)?))$/g,
                "Введите координаты в правильном формате. Например: 55.673513, 37.631055"),
        parkingSpaceNumbers: string(),
        parkingSpaceCount: number()
            .when('parkingSpacesType', {
                is: ParkingSpacesType.count,
                then: number().min(1, "Слишком маленькое число").max(99999, "Слишком большое число")
            }),
        parkingSpacesType: mixed<ParkingSpacesType>(), //.required(requiredMessage),
        priceForOneHour: string()
            .required(requiredMessage),
        // priceForThreeHours: string()
        //     .required(requiredMessage),
        // priceForOneDay: string()
        //     .required(requiredMessage),
    });

    const handleSave = async (values: IParkingInfo) => {
        try {
            if (values.parkingSpacesType === ParkingSpacesType.numbers)
                await apiParkingSpacesValidateNumbers(values.parkingSpaceNumbers);
            else
                await apiParkingSpacesValidateCount(values.parkingSpaceCount);
        }catch (error) {
            let err: string = 'Ошибка'
            if (typeof error === 'string') {
                err = error
            } else if (error instanceof Error) {
                err = error.message
            }
            setErrMsg(err);
            if (err.length > 0) {
                setModalIsOpen(true);
                return;
            }
        }
        setIsLoading(true);
        await apiUpdateParking(values);
        setParkingInfo((await apiGetParkingInfo())[0]);
        setIsEditingMode(!isEditingMode);
        setIsLoading(false);
    };

    if (isLoading) return <ContentLoader/>
    return (<PageContainer>
        <CardContainer>
            <Formik
                initialValues={parkingInfo}
                validationSchema={ParkingInfoSchema}
                onSubmit={handleSave}
            >
                {({values, handleSubmit, setFieldValue}) => (
                    <Form onSubmit={handleSubmit}>
                        <EditButtonContainer>
                            <SubHeaderContainer>Статус</SubHeaderContainer>
                            {!isEditingMode && <TextLink text="Редактировать" color="#4FB08D" onClick={() => setIsEditingMode(!isEditingMode)}/>}
                        </EditButtonContainer>
                        <SectionContainer>
                            <ToggleContainer>
                                <div>
                                    {<Field id="notActive" type="radio" name="parkingStatus"
                                           value={ParkingStatus.notActive}
                                           disabled={!isEditingMode}
                                           onChange={() => setFieldValue("parkingStatus", ParkingStatus.notActive)}
                                    />}
                                    <label style={{cursor: isEditingMode ? "pointer" : "default"}} htmlFor="notActive">Не активна</label>
                                </div>
                                <div>
                                    <Field id="active" type="radio" name="parkingStatus"
                                           value={ParkingStatus.active}
                                           disabled={!isEditingMode}
                                           onChange={() => setFieldValue("parkingStatus", ParkingStatus.active)}
                                    />
                                    <label style={{cursor: isEditingMode ? "pointer" : "default"}} htmlFor="active">Активна</label>
                                </div>
                            </ToggleContainer>
                        </SectionContainer>
                        <SubHeaderContainer>Описание</SubHeaderContainer>
                        <SectionContainer>
                            <CustomTextField
                                id="title"
                                title="Название"
                                value={values.title}
                                placeholder="Укажите название"
                                isEditing={isEditingMode}
                            />
                            <CustomTextField
                                id="description"
                                title="Описание"
                                value={values.description}
                                placeholder="Добавте краткое описание вашей парковки,чтобы водители знали больше об особенностях, местоположении и услугах"
                                multiline
                                rows={3}
                                isEditing={isEditingMode}
                            />
                            <CustomTextField
                                id="parkingRules"
                                title="Правила парковки"
                                value={values.parkingRules}
                                placeholder="Дополните описание имеющимися правилами для пользователей, если они имеются"
                                multiline
                                rows={2}
                                isEditing={isEditingMode}
                            />
                            <CustomTextField
                                id="workSchedule"
                                title="График работы"
                                value={values.workSchedule}
                                placeholder="Укажите график работы. Например пн-сб - круглосуточно, вс с 09:00-22:00"
                                isEditing={isEditingMode}
                            />
                            <CustomTextField
                                id="contacts"
                                title="Контакты"
                                value={values.contacts}
                                placeholder="Укажите контактные данные по которым пользователи смогут связаться при возникновении вопросов."
                                isEditing={isEditingMode}
                            />
                        </SectionContainer>
                        <SubHeaderContainer>Расположение</SubHeaderContainer>
                        <SectionContainer>
                            <CustomTextField
                                id="location"
                                title="Адрес"
                                value={values.location}
                                placeholder="Добавьте адрес"
                                isEditing={isEditingMode}
                            />
                            <CustomTextField
                                id="geoPosition"
                                title="Гео-позиция шлагбаума \ворот\въезда"
                                value={values.geoPosition.toString()}
                                placeholder="Укажите геопозицию для въезда на парковку, используя координаты широты и долготы. Например: 55.673513, 37.631055"
                                multiline
                                rows={3}
                                isEditing={isEditingMode}
                            />
                        </SectionContainer>
                        <SubHeaderContainer>Парковочные места</SubHeaderContainer>
                        <SectionContainer style={{paddingLeft: 45}}>
                            {values.parkingSpacesType == ParkingSpacesType.numbers &&  <VariableTextField
                                id="parkingSpaceNumbers"
                                parkingSpacesType={ParkingSpacesType.numbers}
                                isEditing={isEditingMode}
                                onChangeType={(e) => setFieldValue("parkingSpacesType", e.target.value)}
                                placeholder={"Укажите доступные для бронирования парковочные места через “;”. Например: 14; 57; 78; этаж 2 место 199"}
                            />}
                            {values.parkingSpacesType == ParkingSpacesType.count && <VariableTextField
                                id="parkingSpaceCount"
                                parkingSpacesType={ParkingSpacesType.count}
                                isEditing={isEditingMode}
                                onChangeType={(e) => {setFieldValue("parkingSpacesType", e.target.value); setFieldValue("parkingSpaceCount", 0)}}
                                placeholder={"Укажите количество доступных для бронирования парковочных мест одной цифрой."}
                                onChange={(e) => setFieldValue("parkingSpaceCount", parseInt(e.target.value) )}
                            />}
                        </SectionContainer>
                        <SubHeaderContainer>Тарифы</SubHeaderContainer>
                        <SectionContainer>
                            <CustomTextField
                                id="priceForOneHour"
                                title="Цена за 1 час"
                                value={values.priceForOneHour}
                                placeholder="Укажите цену в рублях"
                                isEditing={isEditingMode}
                            />
                        </SectionContainer>
                        <FocusError/>
                        {isEditingMode && <ButtonStyled type="submit" size="small">Сохранить</ButtonStyled>}
                    </Form>
                )}
            </Formik>
        </CardContainer>
        <Dialog open={modalIsOpen} onClose={handleClose}>
            <DialogContent>
                <DialogContentText>{errMsg}</DialogContentText>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>Понятно</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    </PageContainer>);
}

export default ParkingCard;