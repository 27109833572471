import styled from 'styled-components'

export const Container = styled.div`
    padding: 10px;
    max-width: 400px;
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    & > button{
      margin-left: auto;
    }
`;

export const ContentContainer = styled.div`
    width: 270px;
    display: flex;
    flex-direction: column;
    margin: auto;
    & > button{
      margin-inline: auto;
      margin-top: 30px;
      width: 150px;
      .MuiTouchRipple-root{
        background: #9EDCA8;
        border-radius: 3px;
      }
      .MuiButton-label{
        z-index: 1;
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
      }
    }
  .MuiInput-input{
    text-align: center;
  }
  .MuiInputBase-root{
    width: 250px;
    align-self: center;
    margin-top: 10px;
  }
`;

export const TitleContainer = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
`;

export const TextContainer = styled.div`
  font-size: 16px;    
`;

export const LineContainer = styled.div`
  font-size: 16px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  & > div:first-child{
    font-size: 16px;
    font-weight: bold;
  }
`;

export const CostContainer = styled.div`
  margin-top: 20px;
  align-self: center;
  height: 40px;
  & > div{
    align-items: center;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
  }
`;

export const LoaderContainer = styled.div`
  width: 290px;
  display: flex;
  justify-content: center;
`;