import React, {useState} from 'react';
import {Checkbox, Table as MaterialTable, TableBody, TableCell, TableRow,} from '@material-ui/core'
import TableHead from './TableHead'
import {HeadCell, IFilter, ItemKey, KeysOfType} from '../../Models/headCell'
import {TableContainer} from './styled'

interface TableProps<T> {
    data: Array<T>,
    primaryKey: KeysOfType<T, string>;
    defaultOrderField: ItemKey<T>;
    defaultOrder?: 'asc' | 'desc';
    headCells: HeadCell<T>[];
    onSelectItems?: (items: T[]) => void;
    selectedItems?: Array<T>
    onRowClick?: (item: T) => void;
    rowClass?: string | ((item: T) => string)
}

interface OrderSettings<T> {
    orderBy: ItemKey<T>;
    order: 'asc' | 'desc';
}

function Table<T>({
                      data,
                      defaultOrderField,
                      defaultOrder = 'asc',
                      headCells,
                      onSelectItems,
                      selectedItems,
                      primaryKey,
                      onRowClick,
                      rowClass,
                  }: TableProps<T>) {

    const [orderSettings, setOrderSettings] = useState<OrderSettings<T>>({
        order: defaultOrder,
        orderBy: defaultOrderField
    });
    const [columns, setColumns] = useState<HeadCell<T>[]>(headCells);
    const [searchItems, setSearchItems] = useState<Map<ItemKey<T>, IFilter>>(new Map());

    const toggleVisibleColumn = (item: HeadCell<T>) => {
        setColumns(prev => {
            let column = prev.find(x => x.label === item.label);
            if (column)
                column.isVisible = !column.isVisible;
            return [...prev];
        });
        if (item.isSearchable && searchItems.has(item.key))
            handleSearch(item.key, "");
    };

    const handleSort = (property: ItemKey<T>) => {
        if (orderSettings.orderBy !== property)
            setOrderSettings({orderBy: property, order: "desc"});
        else
            setOrderSettings(prev => ({...prev, order: prev.order === "asc" ? "desc" : "asc"}));
    };
    // TODO убрать any
    const sort = (a: any, b: any) => {
        let {order, orderBy} = orderSettings;
        let valA = typeof orderBy !== "function" ? a[orderBy] : orderBy(a);
        let valB = typeof orderBy !== "function" ? b[orderBy] : orderBy(b);
        let res = 0;
        if (valA < valB)
            res = 1;
        if (valA > valB)
            res = -1;
        return order === 'desc' ? res : -res;
    };

    const getValueFromItem = (item: T, property: ItemKey<T>) => {
        return ((typeof property !== "function" ? item[property] : property(item)) as unknown) ?? '';
    };

    const handleSelectAll = (e: React.ChangeEvent) => {
        let selected: T[] = selectedItems?.length === data.length ? [] : data;
        onSelectItems && onSelectItems(selected);
    };

    const handleSelectItem = (key: T[keyof T]) => (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        if (!selectedItems)
            return;
        let selected: T[];
        if (selectedItems.some(x => x[primaryKey] === key))
            selected = selectedItems.filter(x => x[primaryKey] !== key);
        else
            selected = [...selectedItems.concat(data.filter(x => x[primaryKey] === key))];
        onSelectItems && onSelectItems(selected);
    };

    const handleSearch = (property: ItemKey<T>, filter: string | string[]) => {
        filter = typeof filter === 'string' ? filter.toLowerCase() : filter.map(x => x.toLowerCase());
        let cell = headCells.find(x => x.key === property);
        setSearchItems(prev => {
            prev.set(property, {value: filter, compareType: cell?.compareType ?? 'contains'});
            return new Map(prev);
        });
    };

    const isSelected = (key: T[keyof T]) => selectedItems?.some(x => x[primaryKey] === key);

    const filter = (data: T[]) => {
        return data.filter(item => {
            let result = true;
            searchItems.forEach((val, key) => {
                let itemValue = (getValueFromItem(item, key) as object).toString().toLowerCase();
                if (!checkFilter(itemValue, val)) {
                    result = false;
                    return;
                }
            });
            return result;
        })
    };

    function checkFilter(item: string, filter: IFilter): boolean {
        const {compareType, value} = filter;
        if (typeof value !== 'string' && value.length === 0) {
            return true;
        }
        let result;
        if (compareType === 'contains') {
            result = typeof value === 'string' ? item.includes(value) : value.some(x => item.includes(x.toLowerCase()));
        } else {
            result = typeof value === 'string' ? item === value
                : value.some(x => item === x.toLowerCase());
        }
        return result;
    }


    const displayedData = filter(data).sort(sort);
    const rowCount = displayedData.length;
    const rowSelected = selectedItems?.length;

    return <TableContainer>
        <MaterialTable>
            <TableHead
                headCells={columns}
                order={orderSettings.order}
                orderBy={orderSettings.orderBy}
                numSelected={rowSelected}
                rowCount={rowCount}
                handleSort={handleSort}
                handleSelectAllClick={handleSelectAll}
                handleSearch={handleSearch}
                toggleVisibleColumn={toggleVisibleColumn}
            />
            <TableBody>
                {displayedData.map((x, i) =>

                    <TableRow
                        key={i}
                        aria-checked={isSelected(x[primaryKey])}
                        selected={isSelected(x[primaryKey])}
                        onClick={() => onRowClick && onRowClick(x)}
                        style={{cursor: onRowClick ? 'pointer' : '', border: "solid 8px white"}}
                        className={typeof rowClass === 'function' ? rowClass(x) : rowClass}
                    >

                        {onSelectItems && <TableCell onClick={handleSelectItem(x[primaryKey])} padding="checkbox">
                            <Checkbox checked={isSelected(x[primaryKey])}/>
                        </TableCell>}
                        {columns.filter(x => x.isVisible).map(({createControl, key}, i) =>
                            <TableCell key={i}>
                                {(createControl && createControl(x)) || (typeof key === 'function' ? key(x) : x[key])}
                            </TableCell>
                        )}
                    </TableRow>
                )}
            </TableBody>
        </MaterialTable>
    </TableContainer>;
}

export default Table;