import React, {useState} from 'react'
import styled from 'styled-components'
import {Button as MaterialButton} from "@material-ui/core";
import {ButtonTypeMap} from "@material-ui/core/Button/Button";
import {ExtendButtonBase} from "@material-ui/core/ButtonBase";
import {OverrideProps} from "@material-ui/core/OverridableComponent";
import {ExtendButtonBaseTypeMap} from "@material-ui/core/ButtonBase/ButtonBase";

export const DefaultStyledButton = (props: { href: string } & OverrideProps<ExtendButtonBaseTypeMap<ButtonTypeMap<{ width?: string, height?: string, onClick: (() => void) | (() => Promise<any>) }>>, 'a'> & any) => {
    const [isWorking, setIsWorking] = useState<boolean>(false);

    const handleClick = async () => {
        if (isWorking) return;
        setIsWorking(true);
        try {
            if(props.onClick)
                await props.onClick();
        } finally {
            setIsWorking(false);
        }
    };

    return (<MaterialButton {...props} disabled={props.disabled || isWorking} onClick={handleClick}/>)
};

export const ButtonStyled = styled(DefaultStyledButton)`
  width: ${(props: any) => props.width ?? '200px'};
  height: ${(props: any) => props.height ?? '44px'};
  cursor: pointer;
  .MuiTouchRipple-root{
    background: rgba(79, 176, 141, 0.81);
    border-radius: 3px;
  }
  .MuiButton-label{
    z-index: 1;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    text-transform: none;
  }
  &.Mui-disabled{
    background: #E6EAF0;
    .MuiButton-label{
      color: #8B98A7;
    }
  }
  &:hover{
    background: initial !important;
    opacity: 0.8;
  }
  
` as ExtendButtonBase<ButtonTypeMap<{ width?: string, height?: string }>>;

export default ButtonStyled
