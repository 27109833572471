import axios from 'axios'
import {History} from "history";
import {PagesEnum} from "./Enums/pages";

export const configureAxios = (history: History) => {
    if(window.location.href.includes('staging/test'))
        axios.defaults.baseURL = '/staging/test'
    axios.interceptors.request.use(config => {
        config.headers["Authorization"] = "bearer " + getAuthToken();
        return config;
    });

    axios.interceptors.response.use(response => response,
        error => {
            if(error.response.status === 401){
                localStorage.removeItem('currentUser');
                sessionStorage.removeItem('currentUser');
                history.push(`/${PagesEnum.login}`)
            }
            return Promise.reject(error);
        })
};


const getAuthToken = () => {

    if (localStorage.getItem('currentUser'))
        return localStorage.getItem('currentUser')
    else
        return sessionStorage.getItem('currentUser')
};

