import React from 'react'
import {Route, Switch} from "react-router";
import {PagesEnum} from "../Enums/pages";
import LoginPage, {UserRole} from "./LoginPage";
import ParkingCard from "./ParkingCard";
import PrivateRoute from "../Components/PrivateRoute";
import MapPage from "./Map";
import StatisticsPage from "./StatisticsPage";
import ParkingOwnerLayout, {MenuParkingOwnerPage} from "../Layouts/ParkingOwner";
import AdminPage from "./AdminPage";
import AdminLayout from "../Layouts/Admin";

const Pages = ({}) => {
    return (
        <Switch>
            <Route path={[`/${PagesEnum.login}`, `/staging/test/${PagesEnum.login}`]}>
                <LoginPage/>
            </Route>
            <PrivateRoute path={[`/${PagesEnum.adminPage}`,`/staging/test/${PagesEnum.adminPage}`]} role={UserRole.MainAdmin}>
                <AdminLayout>
                    <AdminPage/>
                </AdminLayout>
            </PrivateRoute>
            <Route path={[`/${PagesEnum.map}/:userId`, `/staging/test/${PagesEnum.map}/:userId`]}>
                <MapPage/>
            </Route>
            <PrivateRoute path={[`/${PagesEnum.statistics}`,`/staging/test/${PagesEnum.statistics}`]} role={UserRole.ParkingOwner}>
                <ParkingOwnerLayout menuItem={MenuParkingOwnerPage.statistics}>
                    <StatisticsPage/>
                </ParkingOwnerLayout>
            </PrivateRoute>

            <PrivateRoute path={[`/${PagesEnum.parkingCard}`,`/staging/test/${PagesEnum.parkingCard}`, '']} role={UserRole.ParkingOwner}>
                <ParkingOwnerLayout menuItem={MenuParkingOwnerPage.parkingCard}>
                    <ParkingCard/>
                </ParkingOwnerLayout>
            </PrivateRoute>
        </Switch>
    )
};

export default Pages;
