import React, {ChangeEvent, useState} from 'react';
import {PageContainer, CardContainer, SectionContainer} from './styled';
import {TextField} from "@material-ui/core";
import ButtonStyled from "../../Components/Button";
import {SubHeaderContainer} from "../ParkingCard/styled";
import {apiNotifyAllUsers} from "../../Api/Admin";

const AdminPage = () => {
    const [text, setText] = useState<string>("");
    const onTextChange = (e: ChangeEvent<{ value: string }>) => {
        setText(e.target.value)
    };
    const handleSend = () => {
        if (text.length === 0) return;
        apiNotifyAllUsers(text);
    };
    return (
        <PageContainer>
            <CardContainer>
                <SubHeaderContainer>Отправить сообщение всем пользователям сервиса</SubHeaderContainer>
                <SectionContainer>
                    <TextField
                        label="Сообщение"
                        rows={2}
                        multiline
                        variant="outlined"
                        size="small"
                        value={text}
                        onChange={onTextChange}
                    />
                </SectionContainer>
                <ButtonStyled onClick={() => handleSend()} size="small">Отправить</ButtonStyled>
            </CardContainer>
        </PageContainer>
    );
}

export default AdminPage;