import axios from 'axios'

export const apiGetMarkAll = () =>
    axios.get(`/api/Map/allMarks`).then(resp => resp.data);

export const apiChooseParking = (userId: string, parkingId: string) => axios.post(`/api/map/ChooseParking`, {userId, parkingId});

export const apiMapGetParkingInfo = (parkingId: string) =>
    axios.get(`/api/Map/${parkingId}`).then(resp => resp.data);

export const apiCalculateBookingCost = (parkingId: string, parkingTime: number) =>
    axios.post(`/api/Map/calculate`, {parkingId, parkingTime}).then(resp => resp.data);
