import React from 'react'
import {Container, ContentContainer, GlobalSnackbarStyled, MenuContainer, PageContainer} from "./styled";
import {
    AppBar,
    IconButton,
    Toolbar
} from "@material-ui/core";
import {PagesEnum} from "../../Enums/pages";
import {ReactComponent as ExitIcon} from "../../Images/exit.svg";
import {useHistory} from "react-router-dom";

interface IAdminLayout {
    children: React.ReactNode | React.ReactNodeArray,
}

const AdminLayout = ({children}: IAdminLayout) => {
    const history = useHistory();
    const handleLogout = () => {
        localStorage.removeItem('currentUser');
        sessionStorage.removeItem('currentUser');
        history.push(`/${PagesEnum.login}`)
    };

    return (<PageContainer>
        <GlobalSnackbarStyled/>
        <AppBar position="fixed">
            <p>AltParking</p>
            <Toolbar variant="dense"/>
            <MenuContainer>
                <p>Admin</p>
                <IconButton onClick={handleLogout}><ExitIcon/></IconButton>
            </MenuContainer>
        </AppBar>
        <Container>
            <ContentContainer>
                {children}
            </ContentContainer>
        </Container>
    </PageContainer>)
};

export default AdminLayout