import React, {useState, useEffect} from 'react';
import {Button, Dialog, DialogActions, DialogContent, IconButton} from "@material-ui/core";
import {CardContainer} from "./styled";
import {apiGetParkingInfo, apiGetParkingStatisticsInfo} from "../../Api/Parking";
import ContentLoader from "../../Components/ContentLoader";
import {HeadCell} from "../../Models/headCell";
import Table from "../../Components/Table";
import {ReactComponent as ExcelIcon} from "../../Images/excel_icon.svg";

export const russianNameFromParkingSessionStatus: string[] = ['Забронирован', 'На парковке', 'Оплачен', 'Завершен', 'Отменен', 'Не выехал'];

export interface IParkingSessionInfo {
    index: number;
    sessionNumber: string;
    created: Date;
    startOfParking: Date;
    paidTime: Date;
    endOfParking: Date;
    parkingTime: string;
    parkingSpace: string;
    carNumber: string;
    cost: number;
    status: ParkingSessionStatus;
}

export enum ParkingSessionStatus {
    Booked = '0',
    InTheParkingPlace = '1',
    Paid = '2',
    Completed = '3',
    Canceled = '4',
}

const StatisticsPage = () => {
    const [data, setData] = useState<Array<IParkingSessionInfo>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const handleClose = () => {
        setModalIsOpen(false);
    };
    useEffect(() => {
        (async () => {
            let statistics: IParkingSessionInfo[] = (await apiGetParkingStatisticsInfo((await apiGetParkingInfo())[0].parkingId)).map((x: any, i: number) => ({
                index: i + 1,
                sessionNumber: x.id,
                created: x.created ? new Date(x.created) : null,
                paidTime: x.paidTime ? new Date(x.paidTime) : null,
                startOfParking: x.startOfParking ? new Date(x.startOfParking) : null,
                endOfParking: x.endOfParking ? new Date(x.endOfParking) : null,
                parkingSpace: x.parkingSpace,
                carNumber: x.carNumber,
                cost: x.cost,
                status: x.status,
            }));

            setData(statistics);
            setIsLoading(false);
        })()
    }, []);

    const getParkingTime = (startOfParking: Date, endOfParking: Date) => {
        if (!startOfParking || !endOfParking)
            return '-'
        let parkingMSeconds = Math.abs(endOfParking?.getTime() - startOfParking?.getTime());
        let hours = Math.floor(parkingMSeconds / 60000 / 60);
        let minutes = Math.floor(parkingMSeconds / 60000) % 60;
        let roundedHours = Math.ceil(parkingMSeconds / 60000 / 60);
        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")} (${roundedHours})`
    }

    const header: Array<HeadCell<IParkingSessionInfo>> = [
        {
            key: 'index',
            label: '#',
            isVisible: true,
            isSearchable: true,
        },
        {
            key: 'sessionNumber',
            label: 'Номер сессии',
            isVisible: false,
            isSearchable: true,
        },
        {
            key: 'created',
            label: 'Дата',
            createControl: obj => <>{obj.created.toLocaleDateString()}</>,
            isVisible: true,
            isSearchable: true,
        },
        {
            key: 'created',
            label: 'Время брони',
            createControl: obj => <>{!obj.created ? '-' : obj.created.toLocaleTimeString([], {timeStyle: 'short'})}</>,
            isVisible: true,
            isSearchable: true,
        },
        {
            key: 'startOfParking',
            label: 'Въезд',
            createControl: obj => <>{!obj.startOfParking ? '-' : obj.startOfParking.toLocaleTimeString([], {timeStyle: 'short'})}</>,
            isVisible: true,
            isSearchable: true,
        },
        {
            key: 'paidTime',
            label: 'Окончание сессии',
            createControl: obj => <>{!obj.paidTime ? '-' : obj.paidTime.toLocaleTimeString([], {timeStyle: 'short'})}</>,
            isVisible: true,
            isSearchable: true,
        },
        {
            key: 'endOfParking',
            label: 'Выезд',
            createControl: obj => <>{!obj.endOfParking ? '-' : obj.endOfParking.toLocaleTimeString([], {timeStyle: 'short'})}</>,
            isVisible: true,
            isSearchable: true,
        },
        {
            key: 'parkingTime',
            label: 'Время на парковке',
            createControl: obj => <>{getParkingTime(obj.startOfParking, obj.endOfParking)}</>,
            isVisible: true,
            isSearchable: true,
        },
        {
            key: 'parkingSpace',
            label: 'Место',
            isVisible: true,
            isSearchable: true,
        },
        {
            key: 'carNumber',
            label: 'Номер машины',
            isVisible: true,
            isSearchable: true,
        },
        {
            key: 'cost',
            label: 'Сумма оплаты',
            createControl: obj => <>{obj.cost ? obj.cost + ' руб' : '-'}</>,
            isVisible: true,
            isSearchable: true,
        },
        {
            key: 'status',
            label: 'Статус',
            createControl: obj => <div
                style={{whiteSpace: 'nowrap'}}>{russianNameFromParkingSessionStatus[obj.status as unknown as number]}</div>,
            isVisible: true,
            isSearchable: true,
        },
    ];

    const getRowColorByStatus = (item: IParkingSessionInfo) => {
        if (item.status == ParkingSessionStatus.InTheParkingPlace || item.status == ParkingSessionStatus.Booked)
            return 'yellow';
        if (item.status == ParkingSessionStatus.Paid || item.status == ParkingSessionStatus.Completed)
            return 'green';
        if (item.status == ParkingSessionStatus.Canceled)
            return 'gray';
        return '';
    };

    if (isLoading) return <ContentLoader/>
    return (<>
        <CardContainer>
            {/*<IconButton onClick={ () => setModalIsOpen(true)}><ExcelIcon/></IconButton>*/}
            <Table data={data} primaryKey={'sessionNumber'} defaultOrderField="index" headCells={header}
                   rowClass={getRowColorByStatus}/>
        </CardContainer>
        <Dialog open={modalIsOpen} onClose={handleClose}>
            <DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>Понятно</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    </>);
}

export default StatisticsPage;