import React, {useState} from 'react'
import {Container, ContentContainer, CostContainer, HeaderContainer, LineContainer,
    LoaderContainer,
    TextContainer, TitleContainer} from "./styled";
import {Button, Divider, IconButton, TextField} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import {apiChooseParking} from "../../../Api/Map";
import {useParams} from "react-router";
import ContentLoader from "../../../Components/ContentLoader";
import {IParkingInfo} from "../../../Models/parkingInfo";

interface IMarkInfo {
    freeParkingSpacesCount: number;
    parkingId: string;
    parkingInfoModel: IParkingInfo;
    isLoadingDrawer: boolean;
    handleCloseInfo: () => void;
}


const ParkingInfo = ({parkingId, handleCloseInfo, freeParkingSpacesCount, parkingInfoModel, isLoadingDrawer}: IMarkInfo) => {
    const redirectBotUrl = window.location.href.includes('test-legacy') ? 'https://t.me/LabSSDemoBot' : 'https://t.me/altparkingbot';
    const [parkingTime, setParkingTime] = useState<number>(0);
    const [cost, setCost] = useState<number>(0);
    const {userId} = useParams<{ userId: string }>();

    const handleRedirectToBot = async () => {
        await apiChooseParking(userId, parkingId);
        window.location.href = redirectBotUrl;
        setTimeout(window.close, 500);
        // window.close();
    };

    const handleChangeTime = (e: React.ChangeEvent<HTMLInputElement>) => {
        let time = Number.parseInt(e.target.value);
        if (time > 9999) return;
        setParkingTime(time);
        if (!isNaN(time)) {
            setCost(time * Number.parseInt(parkingInfoModel.priceForOneHour));
        }
    };

    if (isLoadingDrawer) return <LoaderContainer><ContentLoader/></LoaderContainer>
    return (<Container>
        <HeaderContainer>
            <h3>Информация о парковке</h3>
            <IconButton onClick={handleCloseInfo}>
                <Close/>
            </IconButton>
        </HeaderContainer>
        <Divider/>
        <ContentContainer>
            <TitleContainer>{parkingInfoModel.title}</TitleContainer>
            <LineContainer>
                <div>Свободных мест: </div>
                <div>{freeParkingSpacesCount}</div>
            </LineContainer>
            <TitleContainer>Адрес: </TitleContainer>
            <TextContainer>{parkingInfoModel.location}</TextContainer>
            <TitleContainer>Правила: </TitleContainer>
            <TextContainer>{parkingInfoModel.parkingRules}</TextContainer>
            <TitleContainer>График работы: </TitleContainer>
            <TextContainer>{parkingInfoModel.workSchedule}</TextContainer>
            <TitleContainer>Тариф: </TitleContainer>
            <TextContainer>1 час - {parkingInfoModel.priceForOneHour} рублей</TextContainer>
            <LineContainer style={{fontStyle: "italic"}}>
                Для уточнения стоимости, укажите количество часов, планируемое для парковки.
                Расчёт предварительный.
                Оплата за парковочную сессию будет окончательно рассчитана в момент её завершения.
            </LineContainer>
            <TextField
                disabled={freeParkingSpacesCount === 0}
                value={isNaN(parkingTime) || parkingTime === 0 ? "" : parkingTime}
                placeholder="Планируемое время парковки"
                onChange={handleChangeTime}/>
            <CostContainer>
                <div>{isNaN(parkingTime) || parkingTime === 0 ? "" : cost + " руб."}</div>
                {freeParkingSpacesCount === 0 && <div style={{fontSize: '18px'}}>Cвободных мест в данный момент нет</div>}
            </CostContainer>
            <Button variant="outlined" disabled={freeParkingSpacesCount === 0} onClick={handleRedirectToBot}>Забронировать</Button>
        </ContentContainer>
    </Container>)
};

export default ParkingInfo
