import React, {useState} from 'react';
import {TextFieldStyled} from "./styled";

interface SearchInputProps {
    id: string;
    property: any;
    label: string;
    handleSearch?: (property: any, filter: string) => void;
}

const SearchInput = ({id, property, label, handleSearch}: SearchInputProps) => {
    const [searchLine, setSearchLine] = useState<string>('');
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchLine(e.target.value);
        handleSearch && handleSearch(property, e.target.value);
    };
    return (
        <TextFieldStyled
            id={id}
            label={label}
            value={searchLine}
            onChange={handleChange}
        />
    );
};

export default SearchInput;