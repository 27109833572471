import React from 'react'
import {TextField as MeterialTextField} from '@material-ui/core'
import {useField} from 'formik';
import {StandardTextFieldProps} from "@material-ui/core/TextField/TextField";

const TextFieldStyled = (props: StandardTextFieldProps) => {
    const [field, meta, helpers] = useField(props.id ?? '');
    return (<MeterialTextField
        {...field}
        {...props}
        variant="outlined"
        size="small"
        error={!!(meta.error && meta.touched)}
        helperText={meta.touched && meta.error || ' '}
    />)
};

export default TextFieldStyled;