import styled from 'styled-components'
import { TextField } from '@material-ui/core'

export const TextFieldStyled = styled(TextField)`
    .MuiInputLabel-formControl{
      white-space: nowrap;
      position: relative;
      top: 10px;
      margin-right: 15px;
      //word-wrap: normal;
    }
    
    .MuiInputBase-formControl{
      top: -7px;
      //min-width: ;
    }
`;