import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Pages from "./Pages";
import {BrowserRouter} from "react-router-dom";
import {configureAxios} from "./configureAxios";
import {createBrowserHistory} from 'history'

const history = createBrowserHistory();
configureAxios(history);

ReactDOM.render(
    <BrowserRouter>
        <React.StrictMode>
            <Pages/>
        </React.StrictMode>
    </BrowserRouter>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
