import {ParkingSpacesType} from "../Enums/parkingSpacesType";
import {ParkingStatus} from "../Enums/parkingStatus";

export interface IParkingInfo {
    parkingStatus: ParkingStatus;
    title: string;
    description: string;
    parkingRules: string;
    workSchedule: string;
    contacts: string;
    location: string;
    geoPosition: string;
    parkingSpaceNumbers: string;
    parkingSpaceCount: number;
    parkingSpacesType: ParkingSpacesType;
    priceForOneHour: string;
    priceForThreeHours: string;
    priceForOneDay: string;
}

export const parkingInfoInitial: IParkingInfo = {
    contacts: "",
    description: "",
    geoPosition: "",
    location: "",
    parkingRules: "",
    parkingSpaceNumbers: "",
    parkingSpaceCount: 0,
    parkingSpacesType: ParkingSpacesType.numbers,
    priceForOneDay: "",
    priceForOneHour: "",
    priceForThreeHours: "",
    workSchedule: "",
    title: "",
    parkingStatus: ParkingStatus.notActive,
};
