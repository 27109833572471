import React from 'react'
import {Container, ContentContainer, GlobalSnackbarStyled, MenuContainer, PageContainer} from "./styled";
import {
    AppBar,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Toolbar
} from "@material-ui/core";
import {PagesEnum} from "../../Enums/pages";
import {ReactComponent as ExitIcon} from "../../Images/exit.svg";
import {useHistory} from "react-router-dom";

export enum MenuParkingOwnerPage {
    parkingCard = 0,
    statistics = 1,
}

interface IParkingOwnerLayout {
    children: React.ReactNode | React.ReactNodeArray,
    menuItem: number,
}

const ParkingOwnerLayout = ({children, menuItem}: IParkingOwnerLayout) => {
    const history = useHistory();
    const handleLogout = () => {
        localStorage.removeItem('currentUser');
        sessionStorage.removeItem('currentUser');
        history.push(`/${PagesEnum.login}`)
    };

    return (
        <PageContainer>
            <GlobalSnackbarStyled/>
            <AppBar position="fixed">
                <p>AltParking</p>
                <Toolbar variant="dense"/>
                <MenuContainer>
                    <p>Контакты</p>
                    <IconButton onClick={handleLogout}><ExitIcon/></IconButton>
                </MenuContainer>
            </AppBar>
            <Container>
                <List>
                    <ListItem button selected={menuItem === MenuParkingOwnerPage.parkingCard}
                              onClick={() => history.push(`/${PagesEnum.parkingCard}`)}>
                        <ListItemText primary={"Карточка парковки"}/>
                    </ListItem>
                    <ListItem button selected={menuItem === MenuParkingOwnerPage.statistics}
                              onClick={() => history.push(`/${PagesEnum.statistics}`)}>
                        <ListItemText primary={"Статистика"}/>
                    </ListItem>
                </List>
                <ContentContainer>
                    {children}
                </ContentContainer>
            </Container>
        </PageContainer>)
};

export default ParkingOwnerLayout