import styled from 'styled-components';

export const PageContainer = styled.div`
    display: flex;
    flex-direction: row;
    min-width: 100vw;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
`;

export const LoginContainer = styled.div`
    display: flex;
    justify-content: center;
    border-radius: 31px;
    flex-direction: column;
    padding: 20px 40px 60px 40px;
    z-index: 1;
    background: white;
    align-items: center;
    & > div {
      font-size: 20px;
      margin-bottom: 10px;
    }
    button {
      width: 250px;
      margin-top: 20px;
    }
    & > .MuiTextField-root{
      width: 300px;
      margin: 10px 0;
      height: 30px;
    }
    & > .MuiFormControlLabel-root{
      margin-left: -5px;
      width: 100%;
      & > .MuiCheckbox-colorSecondary.Mui-checked {
        color: rgba(79, 176, 141, 0.81);
      }
      & > .MuiTypography-body1{
        font-size: 14px;
      }
    }
`;


export const ImageStyled = styled.img`
    position: absolute;
    opacity: 0.6;
    height: 100vh;
    width: 100vw;
    object-fit:cover;
`;

export const ErrorContainer = styled.div`
    color: red;
    text-align: center;
    max-width: fit-content;
`;