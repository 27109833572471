import styled from 'styled-components';

export const FieldContainer = styled.div`
  justify-content: left;
  display: flex;
  font-size: 20px;
  align-items: end;
  margin-bottom: 10px;
  min-height: 40px;

  .MuiInputBase-input {
    font-size: 14px;
  }

  & > div:last-child {
    width: 100%;
  }
  
  .MuiOutlinedInput-root {
    min-height: 38px;
  }
`;

export const TitleContainer = styled.div`
  margin-right: 10px;
  width: 200px;
  color: gray;
  min-height: 38px;
  display: flex;
  align-items: flex-start;
`;

