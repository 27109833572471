import React, {useEffect, useRef, useState} from 'react'
import {Map, YMaps, YMapsApi} from "react-yandex-maps";
import {SwipeableDrawer} from "@material-ui/core";
import {StyledContainer} from "./styled";
import ParkingInfo from "./ParkingInfo";
import MarksGenerator from "./MarksGenerator";
import ContentLoader from "../../Components/ContentLoader";
import {apiGetMarkAll, apiMapGetParkingInfo} from "../../Api/Map";
import {IParkingInfo, parkingInfoInitial} from "../../Models/parkingInfo";

export interface IMarkInfo {
    latitude: number;
    longitude: number;
    parkingSpaceCount: number;
    parkingId: string;
}

const MapPage = ({}) => {
    const [helperState, setHelperState] = useState<IMarkInfo | undefined>(undefined);
    const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [marksInfo, setMarksInfo] = useState<IMarkInfo[]>([]);
    const [parkingInfoModel, setParkingInfoModel] = useState<IParkingInfo>(parkingInfoInitial);
    const [isLoadingDrawer, setIsLoadingDrawer] = useState<boolean>(true);
    const mapRef = useRef<any>(null);
    useEffect(() => {
        (async () => {
            setMarksInfo(await apiGetMarkAll());
            setIsLoading(false);
        })()
    }, []);

    const toggleHelper = (mark: IMarkInfo) => async () => {
        setIsLoadingDrawer(true);
        setDrawerIsOpen(true);
        let parkingInfoModel = await apiMapGetParkingInfo(mark.parkingId);
        setParkingInfoModel(parkingInfoModel);
        console.log(parkingInfoModel);
        setHelperState(prev => prev === undefined || prev.parkingId !== mark.parkingId ? mark : undefined);
        setIsLoadingDrawer(false);
    };

    const handleApiAvaliable = (ymaps: YMapsApi) => {
        ymaps.geolocation.get().then((res: any) => {
            mapRef.current.geoObjects.add(res.geoObjects)
            mapRef.current.setCenter(res.geoObjects.position, 12);
        })
    };

    if (isLoading) return <ContentLoader/>
    return (<div>
        <SwipeableDrawer
            anchor="left"
            open={drawerIsOpen}
            onClose={() => setDrawerIsOpen(false)}
            onOpen={() => setDrawerIsOpen(true)}
            disableBackdropTransition={true}
        >
            <ParkingInfo
                parkingInfoModel={parkingInfoModel}
                parkingId={helperState?.parkingId ?? ''}
                freeParkingSpacesCount={helperState?.parkingSpaceCount ?? 0}
                handleCloseInfo={() => setDrawerIsOpen(false)}
                isLoadingDrawer={isLoadingDrawer}
            />
        </SwipeableDrawer>
        <YMaps
            query={{
                ns: 'use-load-option',
                load: 'package.full',
                apikey: '6f452b77-4dc1-40ea-941e-6c6e7cfbf69b'
            }}

        >
            <Map
                defaultState={{center: [55.753265, 37.621150], zoom: 13, controls: ['fullscreenControl', 'geolocationControl', 'rulerControl', 'trafficControl', 'zoomControl'],}}
                width="100%"
                height="100vh"
                onClick={() => setHelperState(undefined)}
                modules={["geolocation", "geocode", "placemark",]}
                onLoad={ymaps => handleApiAvaliable(ymaps)}
                instanceRef={ref => mapRef.current = ref}
            >
                <MarksGenerator
                    marks={marksInfo}
                    onMarkClick={toggleHelper}
                />
            </Map>
        </YMaps>
    </div>)
};

export default MapPage
