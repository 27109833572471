import React from 'react';
import {
    Button,
    Checkbox,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Popover,
    TableCell,
    TableRow,
    TableSortLabel
} from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings';
import SearchInput from '../../SearchInput'
import {HeadCell, ItemKey} from '../../../Models/headCell'
import {CellContent, TableHeadStyled} from './styled';

interface TableHeadProps<T> {
    headCells: HeadCell<T>[];
    orderBy: ItemKey<T>;
    order: 'asc' | 'desc';
    handleSort: (property: ItemKey<T>) => void;
    numSelected?: number;
    rowCount?: number;
    handleSelectAllClick?: (e: React.ChangeEvent) => void;
    handleSearch?: (property: ItemKey<T>, filter: string | string[]) => void;
    toggleVisibleColumn: (item: HeadCell<T>) => void;
}

function TableHead<T>({
                          headCells,
                          orderBy,
                          order,
                          handleSort,
                          numSelected,
                          rowCount,
                          handleSelectAllClick,
                          handleSearch,
                          toggleVisibleColumn
                      }: TableHeadProps<T>) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const createSortHandler = (property: ItemKey<T>) => (event: React.MouseEvent) => {
        handleSort(property);
    };
    return (
        <>
            <TableHeadStyled>
                <TableRow>
                    {(handleSelectAllClick && numSelected !== undefined && rowCount !== undefined) &&
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={handleSelectAllClick}
                            inputProps={{'aria-label': 'select all desserts'}}
                        />
                    </TableCell>}
                    {headCells.filter(x => x.isVisible).map((headCell, i) =>
                        <TableCell
                            key={headCell.label}
                            sortDirection={orderBy === headCell.key ? order : false}
                        >
                            <CellContent>
                                {headCell.isSearchable && handleSearch ?
                                    <SearchInput
                                        id={`search-${headCell.label}`}
                                        property={headCell.key}
                                        label={headCell.label}
                                        handleSearch={handleSearch}
                                    /> :
                                    <div style={{
                                        margin: '6px 15px 0 0',
                                        color: 'rgba(0, 0, 0, 0.54)'
                                    }}>{headCell.label}</div>}
                                <TableSortLabel
                                    active={orderBy === headCell.key}
                                    direction={orderBy === headCell.key ? order : 'asc'}
                                    onClick={createSortHandler(headCell.key)}
                                />
                            </CellContent>
                        </TableCell>
                    )}
                    <TableCell> <Button style={{width: '100%'}} size='small'
                                        onClick={(e) => setAnchorEl(e.currentTarget)}>
                        <SettingsIcon color="action" fontSize="small"/>
                    </Button></TableCell>
                </TableRow>
            </TableHeadStyled>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
            >
                <List>
                    {headCells.map((item, i) => (
                        <ListItem key={i} dense button onClick={() => toggleVisibleColumn(item)}>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={item.isVisible}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{'aria-labelledby': item.label}}
                                />
                            </ListItemIcon>
                            <ListItemText id={item.label} primary={item.label}/>
                        </ListItem>
                    ))}
                </List>
            </Popover>
        </>
    );
}

export default TableHead;