import styled from 'styled-components'

export const TableContainer = styled.div`
  .MuiTableCell-root {
    padding: 5px;

    .MuiFormLabel-filled {
      margin-bottom: 3px;
    }
  }

  .yellow {
    background-color: rgba(245, 240, 99, 0.15) !important;
  }

  .green {
    background-color: rgba(79, 176, 141, 0.15) !important;
  }

  .gray {
    background-color: rgba(199, 190, 190, 0.15) !important;
  }
`;

export const DialStyled = styled.div`
  position: absolute;
  right: 33%;
  top: 0;
  height: 100%;
  z-index: 0;

  button.MuiSpeedDial-fab {
    width: 35px !important;
    height: 35px !important;
    box-shadow: none;
    background-color: transparent;
    color: black;
  }
`;

