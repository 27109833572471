import axios from 'axios'
import {IParkingInfo} from "../Models/parkingInfo";
import {russianNameFromParkingSessionStatus} from "../Pages/StatisticsPage";
import {ParkingSpacesType} from "../Enums/parkingSpacesType";

export const apiUpdateParking = async (model: IParkingInfo) =>
    axios.post(`api/parking/update`, model)
        .then(({data}) => data);

export const apiGetParkingInfo = () =>
    axios.get(`api/Parking/info`).then(resp => resp.data);

export const apiParkingSpacesValidateNumbers = (ParkingSpaceNumbers: string) =>
    axios.post(`api/Parking/validateNumbers`, {ParkingSpaceNumbers})
        .catch((error: any) =>Promise.reject(error?.response?.data))
        .then(({data}:any) => data);

export const apiParkingSpacesValidateCount = (ParkingSpaceCount: number) =>
    axios.post(`api/Parking/validateCount`, {ParkingSpaceCount})
        .catch((error: any) =>Promise.reject(error?.response?.data))
        .then(({data}:any) => data);

export const apiGetParkingStatisticsInfo = (parkingId: string) =>
    axios.get(`api/Parking/statistics/${parkingId}`).then(res => res.data);
