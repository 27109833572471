import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {PagesEnum} from "../../Enums/pages";

export const PrivateRoute = ({children, role, ...rest}: any) => (
    <Route {...rest} render={props => {
        let localCurrentUser = localStorage.getItem('currentUser');
        let sessionCurrentUser = sessionStorage.getItem('currentUser');
        let userRole = localStorage.getItem('role');
        if (!localCurrentUser && !sessionCurrentUser || userRole !== role) {
            return <Redirect to={{pathname: `/${PagesEnum.login}`}}/>
        }
        return children;
    }}/>
);

export default PrivateRoute
