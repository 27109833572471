import styled from 'styled-components';

export const PageContainer = styled.div`
  .MuiAppBar-colorPrimary{
    display: flex;
    flex-direction: row;
    background-color: white;
    box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.25);
    & > p{
      margin-left: 40px;
      color: #4FB08D;
    }
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  p{
    margin-right: 20px;
  }
  color: black;
  margin-left: auto;
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 50px 100px 100px 100px;
    max-width: 1150px;
    button{
      display: flex;
      margin-left: auto;
    }
`;

export const SubHeaderContainer = styled.div`
      font-size: 20px;
      font-weight: bold;
      margin-left: 20px;
      margin-bottom: 20px;
`;

export const SectionContainer = styled.div`
    justify-content: center;
    border-radius: 5px;
    flex-direction: column;
    display: flex;
    border: 2px solid #4FB08D;
    padding: 30px 50px 30px 50px;
    box-shadow: 5px 6px 8px rgba(0, 0, 0, 0.25);
    margin-left: 20px;
    margin-bottom: 30px;
`;

export const EditButtonContainer = styled.div`
  display: flex;
  & > div:first-child{
    width: 100%;
  }
`;

export const ToggleContainer = styled.div`
  display: inline-block;
  overflow: hidden;
  margin-bottom: 10px;
  & > div{
    float: left;
    display: inline-block;
    & > input {
      display: none;
    }
    & > label {
      display: inline-block;
      padding: 0 15px;
      line-height: 34px;
      border: 1px solid #999;
      border-right: none;
      cursor: pointer;
      user-select: none;
      width: 90px;
      text-align: center;
    }
    &:first-child > label {
      border-radius: 6px 0 0 6px;
    }
    &:last-child > label {
      border-radius: 0 6px 6px 0;
      border-right: 1px solid #999;
    }
    &:first-child > input:checked + label {
      background: #D2415B;
      color: white;
    }
    &:last-child > input:checked + label {
      background: #4fb08d;
      color: white;
    }
  }
`;
